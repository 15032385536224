.content {
  background-color: #ffffff;
  height: 100%;
  margin: 64px;
  color: black;
}

.footer {
  color: rgba(0, 0, 0, .50);
}

a {
  color: #1890ff;
  text-decoration: none;
}

a:hover {
  color: #40a9ff;
}
